import React from "react";
import {
  Container,
  Typography,
  Link,
  Chip,
  ThemeProvider,
  createMuiTheme,
} from "@material-ui/core";
import getIcon from "./iconMapping"; // Import the getIcon function

const theme = createMuiTheme({
  palette: {
    type: "dark",
  },
});

function ProjectDetailPage({ project }) {
  const Icon = getIcon(project.linkIcon);

  return (
    <ThemeProvider theme={theme}>
      <Container>
        <Typography variant="h2" gutterBottom>
          {project.name}
        </Typography>
        <Link href={project.link} target="_blank" rel="noopener noreferrer">
          {getIcon(project.linkIcon)} Link
        </Link>
        <Typography variant="body1" gutterBottom>
          {project.description}
        </Typography>
        <Typography variant="h6" gutterBottom>
          Tools:
        </Typography>
        {project.tools.map((tool) => {
          const Icon = getIcon(tool);
          return <Chip icon={Icon ? <Icon /> : null} label={tool} key={tool} />;
        })}
        <Typography variant="h6" gutterBottom>
          Languages:
        </Typography>
        {project.languages.map((language) => {
          const Icon = getIcon(language);
          return (
            <Chip
              icon={Icon ? <Icon /> : null}
              label={language}
              key={language}
            />
          );
        })}
      </Container>
    </ThemeProvider>
  );
}

export default ProjectDetailPage;
