import React from 'react';
import { Card, CardContent, CardMedia, Typography, createMuiTheme, ThemeProvider, makeStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';

const darkTheme = createMuiTheme({
  palette: {
    type: 'dark',
    background: {
      default: '#333',
      paper: '#424242',
    },
  },
});

const useStyles = makeStyles({
  link: {
    textDecoration: 'none',
    '&:hover': {
      backgroundColor: '#616161',
    },
  },
});

const placeholderImage = 'https://via.placeholder.com/140';

function Projects({ projects }) {
  const classes = useStyles();

  return (
    <ThemeProvider theme={darkTheme}>
      <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around' }}>
        {projects.map((project, index) => (
          <Card key={index} component={Link} to={`/projects/${project.getSafeName()}`} style={{ minWidth: 500, maxWidth: 500, margin: 10 }} className={classes.link}>
            <CardMedia
              component="img"
              alt={project.name}
              height="140"
              image={project.images[0] || placeholderImage}
              title={project.name}
              style={project.scaleToFit ? { objectFit: 'contain' } : {}}
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="h2">
                {project.name}
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                {project.shortDescription}
              </Typography>
            </CardContent>
          </Card>
        ))}
      </div>
    </ThemeProvider>
  );
}

export default Projects;