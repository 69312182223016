// Footer.js
import React from "react";
import "./style/Footer.css"; // Import CSS file for styling
import getIcon from "./iconMapping"; // Import the icon mapping

function Footer({ socials }) {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="social-icons">
          {socials.map((social) => (
            <a
              key={social.name}
              href={social.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              {getIcon(social.name)}
            </a>
          ))}
        </div>
        <p>&copy; 2024 Jake Livingston. All rights reserved.</p>
      </div>
    </footer>
  );
}

export default Footer;
